var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('TableIndex', {
    attrs: {
      "title": _vm.title,
      "resource-name": _vm.resourceName,
      "singular-name": _vm.singularName
    },
    scopedSlots: _vm._u([{
      key: "custom_actions",
      fn: function fn(_ref) {
        var item = _ref.item,
          currentActions = _ref.currentActions;
        return [_c('b-link', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-primary",
            modifiers: {
              "hover": true,
              "v-primary": true
            }
          }],
          staticClass: "ml-1",
          attrs: {
            "title": "Sub Categories",
            "to": {
              name: 'sub-categories',
              params: {
                categoryId: item.id
              }
            }
          }
        }, [_c('feather-icon', {
          staticClass: "align-middle text-body",
          attrs: {
            "icon": "ListIcon",
            "size": "16"
          }
        })], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }