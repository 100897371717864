<template>
  <div>
    <TableIndex
      :title="title"
      :resource-name="resourceName"
      :singular-name="singularName"
    >
      <template v-slot:custom_actions="{ item, currentActions }">
        <b-link
            v-b-tooltip.hover.v-primary
            title="Sub Categories"
            class="ml-1"
            :to="{ name: 'sub-categories', params: { categoryId: item.id } }"
        >
          <feather-icon icon="ListIcon"
                        size="16"
                        class="align-middle text-body"
          />
        </b-link>
      </template>
    </TableIndex>
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],
  data() {
    return {
      title: 'categories',
      resourceName: 'categories',
      singularName: 'category',
    }
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e
    }, 500),
  },
}
</script>
